import { gql } from 'Utils/apolloClient'

const reviewsQuery = gql`
  query Campground($slug: String!, $page: Int!, $perPage: Int!) {
    campground(slug: $slug) {
      id
      averageReviewRating
      reviewsCount
      reviews(page: $page, perPage: $perPage) {
        nodes {
          id
          user {
            name
            email
            headshotUrl
          }
          rating
          content
          createdAt
          responses {
            id
            content
          }
        }
      }
      name
      slug
    }
    options {
      externalLinks {
        avatarDefaultUrl
        reviewNewUrl(slug: $slug)
        issueReport
      }
    }
  }`

export default reviewsQuery
