import gravatar from 'gravatar'

export const getUserHeadshotUrl = (avatarDefaultUrl, user) => {
  if (user.headshotUrl) return user.headshotUrl

  return gravatar.url(user.email.trim().toLowerCase(), { s: '48', d: avatarDefaultUrl })
}

export const skeletonReviews = Array.from({ length: 3 }, () => ({
  content: 'Your camper/RV must have the official RVIA factory logo attached in order to camp with us--this',
  createdAt: '00-00-00',
  rating: 5,
  responses: [],
  user: { name: '', email: '', headshotUrl: '' },
}))
