import { memo } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import './Pagination.scss'

const Pagination = ({ currentPage, pagesCount, updatePageSelected }) => {
  const initialPage = currentPage - 1

  const handlePageChange = page => {
    const selectedPage = page.selected + 1
    if (selectedPage !== currentPage) updatePageSelected(selectedPage)
  }

  return (
    <div className="d-flex justify-content-end mt-3">
      <ReactPaginate activeClassName="active" breakLabel="..." breakClassName="page-item"
                     breakLinkClassName="page-link" containerClassName="pagination pagination-salmon m-0"
                     forcePage={initialPage} nextClassName="page-item" nextLinkClassName="page-link"
                     nextLabel="→" marginPagesDisplayed={1} pageClassName="page-item" pageLinkClassName="page-link"
                     pageCount={pagesCount} pageRangeDisplayed={5} previousClassName="page-item"
                     previousLinkClassName="page-link" previousLabel="←" onPageChange={handlePageChange} />
    </div>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  updatePageSelected: PropTypes.func.isRequired,
}

export default memo(Pagination)
