import { getUserHeadshotUrl } from '../Utils'
import RatingWithStars from 'Checkout/Components/RatingWithStars'
import Response from './Response'

const Review = ({ avatarDefaultUrl, review }) => {
  const { rating, content, user, createdAt, responses } = review

  return (
    <div className="row campground-review">

      {user && (
        <div className="col-md-3">
          <img alt={`${user.name} avatar`} className="max-w-full"
               src={getUserHeadshotUrl(avatarDefaultUrl, user)} />

          <br />

          <p className="text-center">{user.name}</p>
        </div>
      )}

      <div className={`col-md-${responses ? '9' : '12'}`}>
        <RatingWithStars className="text-salmon d-flex mb-1" rating={rating} ratingClasses="ms-2" />

        <p>{content}</p>

        <small className="pull-right mb-2">{new Date(createdAt).toLocaleDateString()}</small>

        {responses?.length > 0 && responses.map(response => (<Response key={response.id} response={response} />))}
      </div>
    </div>
  )
}

export default Review
