import 'Shared/timemachine'
import 'react-dates/initialize'

import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Wrapper from './Wrapper'

const Review = ({ campgroundSlug, token }) => {
  window.roverData = { campgroundSlug, token }

  return (
    <ApolloProvider client={apolloClient}>
      <Wrapper slug={campgroundSlug} />
    </ApolloProvider>
  )
}

export default Review
