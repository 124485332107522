import { useEffect, useState } from 'react'
import cnames from 'classnames'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { skeletonReviews } from './Utils'
import reviewsQuery from 'Shared/Reviews/queries/ReviewsQuery'
import { Card, CardBody } from 'reactstrap'
import AlertShowError from 'Shared/Alert/AlertShowError/AlertShowError'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'
import Pagination from './Components/Pagination/Pagination'
import RatingWithStars from 'Checkout/Components/RatingWithStars'
import Review from './Components/Review'
import 'Shared/Skeleton/Skeleton.scss'

const REVIEWS_PER_PAGE = 3

const Wrapper = ({ slug }) => {
  const [getReviews, { data, error, loading }] = useLazyQueryNoCache(reviewsQuery)
  const [currentPage, setCurrentPage] = useState(1)

  const className = cnames('mt-3', { 'skeleton-loading': loading })
  const reviews = loading ? skeletonReviews : data?.campground.reviews.nodes
  const rating = data?.campground.averageReviewRating
  const externalLinks = data?.options.externalLinks
  const totalReviews = data?.campground.reviewsCount || 0
  const totalPages = Math.ceil(totalReviews / REVIEWS_PER_PAGE)

  useEffect(() => {
    getReviews({ variables: { slug, page: currentPage, perPage: REVIEWS_PER_PAGE } })
  }, [slug, currentPage])

  if (error) return <AlertShowError error={error} />

  return (
    <Card className={className} id="reviews">
      <CardBody>
        <h2>
          Reviews
          <a href={externalLinks?.reviewNewUrl} className="btn btn-grey btn-header">Leave a Review</a>
        </h2>

        <div className="border-top border-bottom py-2">
          <Link isExternal to={externalLinks?.issueReport} className="link-grey">
            <FontAwesomeIcon className="me-2" icon="circle-exclamation" />
            Report an issue
          </Link>
        </div>

        <div className="border-bottom py-2 d-flex">
          <strong>Overall Rating:</strong>

          <RatingWithStars className="ms-2 text-salmon d-flex" rating={rating} ratingClasses="ms-2" />
        </div>

        <div>
          {reviews?.map(review => (
            <Review avatarDefaultUrl={externalLinks?.avatarDefaultUrl} key={review.id}
                    review={review} />
          ))}
        </div>

        {totalPages > 1 && (
          <Pagination currentPage={currentPage} pagesCount={totalPages} updatePageSelected={setCurrentPage} />
        )}

      </CardBody>
    </Card>
  )
}

export default Wrapper
